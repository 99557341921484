import * as React from 'react';
import {
  Grid,
  Divider,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { BlueButton } from '../../Components/Components';
import { clearCart, getTotals, removeCustomProductFromCart, removeFromCart } from '../../redux/slice/cartSlice';
import OrderServices from '../../redux/api/OrderServices';
import { getUserDetails } from '../../redux/slice/userSlice';
import { imageBaseUrl } from '../../redux/api/http-common';

const CheckoutPage = () => {
  const [products, setProducts] = React.useState([]);
  const [customproducts, setCustomProducts] = React.useState([]);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.clientuser.token);
  const userInfo = useSelector((state) => state.clientuser.userInfo);
  const [address, setAddress] = useState('');
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleShowInput = () => {
    setIsShown((isShown) => !isShown);
    setAddress(isShown ? '' : userInfo?.address || '');
  };

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  useEffect(() => {
    if (userToken) {
      dispatch(getUserDetails());
    }
  }, [userToken, dispatch]);

  useEffect(() => {
    if (!isShown && !address && userInfo?.address) {
      setAddress(userInfo.address);
    }
    const items = JSON.parse(localStorage.getItem('cartItems'));
    if (items?.products.length > 0) {
      setProducts(
        items?.products.map((res) => ({
          product_id: res._id,
          quantity: res.cartQuantity,
          new_price: res.new_price,
          old_price: res.old_price,
          discount: res.discount,
          selectedSize: !res.selectedSize ? null : res.selectedSize,
        }))
      );
    }
    if (items?.customproducts.length > 0) {
      setCustomProducts(
        items?.customproducts.map((res) => ({
          customProductId: res.customProductId,
          prices: res.prices,
          cartQuantity: res.cartQuantity,
          totalPrice: res.totalPrice,
          customFields: res.customFields,
          booleanFields: res.booleanFields,
        }))
      );
    }
  }, [isShown, address, userInfo]);

  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!userInfo?.address) {
      navigate('/profile');
      toast.error('Deliver Adress is there. Please Update the Profile with Address', { position: 'bottom-left' });
      return;
    }

    if (!address) {
      toast.error('Please write the Delivery Address', {
        position: 'bottom-left',
      });
      return;
    }

    const formData = new FormData();
    formData.append('name', userInfo.name);
    formData.append('phone', userInfo.phone);
    formData.append('delivery_address', address);
    formData.append('sub_total', cart.cartTotalAmount);
    // formData.append("total_shipping_fee", cart.cartTotalShip);
    formData.append('all_totals', Number(cart.cartTotalAmount) + Number(cart.cartTotalShip));
    formData.append('products', JSON.stringify(products));
    formData.append('customQuantity', cart.customQuantity);
    formData.append('customTotal', cart.customTotal);
    formData.append('customProduct', JSON.stringify(customproducts));
    try {
      const res = await OrderServices.create(formData);
      if (res?.data) {
        localStorage.setItem('orderitem', JSON.stringify(res.data.data));
        toast.success('Order Place Sucessfully', {
          position: 'bottom-left',
        });
        navigate('/payment');
        setAddress('');
        setProducts([]);
        dispatch(clearCart());
      }
    } catch (error) {
      if (!error.response.data.message) {
        toast.error(error.message, { position: 'top-right' });
      } else {
        toast.error(error.response.data.message, { position: 'top-right' });
      }
    }
  };

  return (
    <>
      <Grid container className="top-container row-simple-container">
        <Helmet>
          <title>Place Order | PakPrint Wishes </title>
        </Helmet>
        <Grid item xs={10} sm={10} md={7} lg={7} xl={7}>
          <TableContainer sx={{ marginBottom: '50px' }}>
            <Table size="small">
              <TableHead style={{ background: 'white' }}>
                <TableRow>
                  <TableCell className="checkout-table-cell" sx={{ fontWeight: 'bold' }}>
                    Items
                  </TableCell>
                  <TableCell className="checkout-table-cell" align="center" sx={{ fontWeight: 'bold' }}>
                    Price
                  </TableCell>
                  <TableCell align="right" className="checkout-table-cell" sx={{ fontWeight: 'bold' }}>
                    Quantity
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Number(cart?.cartItems?.products.length) + Number(cart?.cartItems?.customproducts.length) === 0 ? (
                  <Box className="cart-empty row-space-around-flex">
                    <Typography className="co-header-text-font1">Your cart is currently empty</Typography>
                    <Box className="start-shopping">
                      <Link to="/" className="row-container">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>
                        <Typography className="co-header-text-font2">Start Shopping</Typography>
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  cart.cartItems?.products &&
                  cart.cartItems?.products.map((res) => (
                    <TableRow sx={{ paddingX: 0 }} key={res._id}>
                      <TableCell className="checkout-table-cells table-cell-img">
                        <img
                          src={imageBaseUrl + res.images[0]}
                          width={'100px'}
                          height="100px"
                          alt={res.product_title}
                        />
                        <Box className="atc-header-text-font2">
                          <Typography className="atc-header-text-font2">{res.product_title}</Typography>
                          {!res?.selectedSize ||
                          Object.keys(res.selectedSize).length === 0 ||
                          !res?.selectedSize?.title ? null : (
                            <Typography className="co-header-text-font6" sx={{ marginLeft: '10px' }}>
                              Size: {res?.selectedSize?.title}
                            </Typography>
                          )}
                        </Box>
                      </TableCell>
                      {!res?.discount || res?.discount === 0 || res?.discount === '0' ? (
                        <TableCell align="center" className="checkout-table-cells">
                          <Typography style={{ color: 'green', fontWeight: '500' }}>
                            Rs.{Number(res.old_price).toFixed(2)}
                          </Typography>
                          <br />
                          <DeleteIcon onClick={() => handleRemoveFromCart(res)} className="pointers gray-color" />
                        </TableCell>
                      ) : (
                        <TableCell align="center" className="checkout-table-cells">
                          <Typography style={{ color: 'green', fontWeight: '500' }}>
                            Rs.{Number(res.new_price).toFixed(2)}
                          </Typography>
                          <del style={{ color: 'rgba(0, 0, 0, 0.5)' }}>Rs.{res.old_price}</del>
                          <br />
                          <Typography color="red">{res.discount}% Off</Typography>
                          <DeleteIcon onClick={() => handleRemoveFromCart(res)} className="pointers gray-color" />
                        </TableCell>
                      )}
                      <TableCell align="right">QTY: {res.cartQuantity}</TableCell>
                    </TableRow>
                  ))
                )}
                {Number(cart?.cartItems?.customproducts.length) === 0
                  ? null
                  : cart.cartItems?.customproducts &&
                    cart.cartItems?.customproducts.map((res) => (
                      <TableRow sx={{ paddingX: 0 }} key={res._id}>
                        <TableCell className="checkout-table-cells table-cell-img">
                          <img
                            src={imageBaseUrl + res.images[0]}
                            width={'100px'}
                            height="100px"
                            alt={res.product_title}
                          />
                          <Typography sx={{ paddingLeft: '10px' }}>{res.titles} (Custom Product)</Typography>
                        </TableCell>
                        <TableCell align="center" className="checkout-table-cells">
                          <Typography style={{ color: 'green', fontWeight: '500' }}>
                            Rs.{Number(res.totalPrice).toFixed(2)}
                          </Typography>
                          <br />
                          <DeleteIcon
                            onClick={() => dispatch(removeCustomProductFromCart(res))}
                            className="pointers gray-color"
                          />
                        </TableCell>
                        <TableCell align="right">QTY: {res.cartQuantity}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={10} sm={10} md={3} lg={3} xl={3} sx={{ marginBottom: '-40px' }}>
          <Box className="checkout-form-box">
            <Box className="checkout-form-title">
              <Typography className="checkout-form-title-text">Proceed to pay</Typography>
            </Box>
            <input
              type="text"
              label="Address"
              placeholder="Enter Address"
              className="checkout-form-input"
              value={address}
              disabled={isShown === false}
              onChange={(e) => setAddress(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox checked={isShown} onChange={toggleShowInput} color="success" />}
              label="Another delivery Address"
            />
            <Typography className="checkout-form-subtitle">Order Summary</Typography>
            <Grid item className="checkout-form-details-text">
              <Typography className="checkout-form-subtitle-detail">
                Items Total({Number(cart?.cartItems?.products.length) + Number(cart?.cartItems?.customproducts.length)}{' '}
                items)
              </Typography>
              <Typography className="checkout-form-subtitle-detail2">
                Rs. {Number(cart?.cartTotalAmount.toFixed(2)) + Number(cart?.customTotal.toFixed(2))}
              </Typography>
            </Grid>
            <Grid item className="checkout-form-details-text">
              <Typography className="co-header-text-font6" sx={{ color: 'red !important' }}>
                Note: Ship Fee Calculate on the base of Distance and Weight.
              </Typography>
              {/* <Typography className="checkout-form-subtitle-detail2">
                Rs. {cart.cartTotalShip}
              </Typography> */}
            </Grid>
            <Divider className="checkout-form-divider" />
            <Grid item className="checkout-form-details-text">
              <Typography className="checkout-form-subtitle-detail">Total</Typography>
              <Typography sx={{ color: '#58A30A' }} className="checkout-form-subtitle-detail2">
                Rs. {(Number(cart.cartTotalAmount) + Number(cart.customTotal)).toFixed(2)}
              </Typography>
            </Grid>
            <Grid className="checkout-btn-box">
              <BlueButton className="checkout-btn" onClick={userInfo ? onSubmit : navigate('/login')}>
                Place Order
              </BlueButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CheckoutPage;
