import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { Icon } from '@iconify/react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ProductServices from '../../../redux/api/ProductServices';
import { getAccessoriesProductMethod, getProductMethod } from '../../../redux/slice/productSlice';
import RichTextEditors from '../../RichText/RichTextEditors';
import GalleryService from '../../../redux/api/GalleryService';
import Iconify from '../../iconify/Iconify';
import GalleyModel from '../../Gallery/GalleyModel';
import { getpublicParentCategoryMethod } from '../../../redux/api/publicParentCatgroySlice';
import { getPublicSubCategoryMethod } from '../../../redux/slice/publicSubCategorySlice';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function UpdateProductDialog({ openEditForm, handleClickOpenEditForm, productItem }) {
  const [folderId, setFolderId] = React.useState(null);
  // Gallery Model
  const [openModel, setOpenModel] = React.useState(false);
  const handleModel = () => {
    setOpenModel(!openModel);
  };
  const parentCategoryData = useSelector((state) => state.publicParentCatgroy);
  const subCategoryData = useSelector((state) => state.publicSubCategory);
  const brandData = useSelector((state) => state.brand);
  const dispatch = useDispatch();

  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [brand, setBrand] = React.useState('Select Brand');
  const [mainCategory, setMainCategory] = React.useState('Select Main Category');
  const [childCategory, setChildCategory] = React.useState('Select Sub Category');
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [newPrice, setNewPrice] = React.useState(0);
  // const [shippingFee, setShippingFee] = React.useState(0);
  const [sizes, setSizes] = React.useState([]);
  const [accessoriesCondition, setAccessoriesCondition] = React.useState(null);
  const [accessoriesType, setAccessoriesType] = React.useState(null);
  const [accessoriesLocation, setAccessoriesLocation] = React.useState(null);
  const [advance, setAdvance] = React.useState(null);
  const [deliverDay, setDeliverDay] = React.useState(null);
  const [productType, setProductType] = React.useState('Product');
  const [featuredProduct, setFeaturedProduct] = React.useState(false);
  const [newArrival, setNewArrival] = React.useState(false);
  const [productCode, setProductCode] = React.useState(null);
  const [buynow, setBuyNow] = React.useState(false);
  const [minQuantity, setMinQuantity] = React.useState(0);
  const [file, setFile] = React.useState([]);
  // console.log(productItem);
  // Folder Category
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (productType === 'Accessories') {
      handleGalleryFolder();
    }
  }, [productType]);

  React.useEffect(() => {
    dispatch(getpublicParentCategoryMethod());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(getPublicSubCategoryMethod(mainCategory));
  }, [mainCategory]);

  const handleGalleryFolder = async () => {
    await GalleryService.getFolder()
      .then((res) => {
        const result = res?.data?.data && res?.data?.data.filter((item) => item?.name === 'Accessories');
        setFolderId(!result || result.length === 0 ? null : result[0]?._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (productItem !== null && productItem?.product_type && productItem?.product_type === 'Product') {
      setProductType(productItem?.product_type);
      setTitle(productItem?.product_title);
      setBrand(!productItem?.product_brand_id?._id ? 'Select Brand' : productItem?.product_brand_id?._id);
      setMainCategory(
        !productItem?.product_category_id?._id ? 'Select Main Category' : productItem?.product_category_id?._id
      );
      setChildCategory(
        !productItem?.product_child_category_id?._id
          ? 'Select Sub Category'
          : productItem?.product_child_category_id?._id
      );
      setSizes(productItem?.sizes || []);
      setAdvance(productItem?.product_advance);
      setFeaturedProduct(!productItem?.featured_product ? null : productItem?.featured_product);
      setNewArrival(productItem?.product_new_arrival ? null : productItem?.product_new_arrival);
      // setShippingFee(productItem?.shiping_fee);
      setDeliverDay(productItem?.delivered_days);
      setDescription(productItem?.product_description);
      setTotalPrice(productItem?.old_price);
      setDiscount(productItem?.discount);
      setProductCode(productItem?.productCode);
      setBuyNow(productItem?.buynow);
      setMinQuantity(productItem?.minQuantity);
      setFile(!productItem?.images || productItem?.images?.length === 0 ? [] : productItem?.images);
      setFolderId(!productItem?.product_category_id?._id ? null : productItem?.product_category_id?._id);
    } else if (productItem !== null && productItem?.product_type && productItem?.product_type === 'Accessories') {
      setProductType(productItem?.product_type);
      setMainCategory(
        !productItem?.product_category_id?._id ? 'Select Main Category' : productItem?.product_category_id?._id
      );
      setChildCategory(
        !productItem?.product_child_category_id?._id
          ? 'Select Sub Category'
          : productItem?.product_child_category_id?._id
      );
      setTitle(productItem?.product_title);
      setBrand(!productItem?.product_brand_id?._id ? 'Select Brand' : productItem?.product_brand_id?._id);
      // setShippingFee(productItem?.shiping_fee);
      setSizes([]);
      setDeliverDay(productItem?.delivered_days);
      setDescription(productItem?.product_description);
      setTotalPrice(productItem?.old_price);
      setDiscount(productItem?.discount);
      setAccessoriesCondition(productItem?.accessories_condition);
      setAccessoriesType(productItem?.accessories_type);
      setAccessoriesLocation(productItem?.accessories_Location);
      setProductCode(productItem?.productCode);
      setFile(!productItem?.images || productItem?.images.length === 0 ? [] : productItem?.images);
      setMinQuantity(productItem?.minQuantity);
    } else {
      setTitle(null);
      setDescription(null);
      setBrand('Select Brand');
      setMainCategory('Select Main Category');
      setChildCategory('Select Sub Category');
      setTotalPrice(0);
      setDiscount(0);
      setNewPrice(0);
      setSizes([]);
      // setShippingFee(0);
      setAdvance(null);
      setDeliverDay(null);
      setProductType('Product');
      setAccessoriesCondition(null);
      setAccessoriesType(null);
      setAccessoriesLocation(null);
      setFeaturedProduct(false);
      setNewArrival(false);
      setBuyNow(false);
      setProductCode(null);
      setFile([]);
      setFolderId(null);
      setMinQuantity(0);
    }
  }, [productItem]);

  // const selectFiles = (event) => {
  //   const selectedFiles = event.target.files;
  //   const imagesFile = [];

  //   if (selectedFiles.length > 5) {
  //     alert('You can only upload a maximum of 5 images');
  //     return;
  //   }

  //   for (let i = 0; i < selectedFiles.length; i += 1) {
  //     imagesFile.push(URL.createObjectURL(selectedFiles[i]));
  //   }

  //   setFile(selectedFiles);
  // };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    // Ensure the value stays within the bounds
    if (value >= 0 && value <= 100) {
      setDiscount(value);
    }
  };

  React.useEffect(() => {
     if (!sizes || sizes.length === 0) {
       const total = totalPrice * (discount / 100);
       setNewPrice(totalPrice - total);
     } else {
       const filteredSizes = sizes.filter((item) => item.itemStatus === false || item.itemStatus === 'false');
       if (!filteredSizes || filteredSizes.length === 0) {
         setNewPrice(0);
         setTotalPrice(0);
         setDiscount(0);
       } else {
         const total = filteredSizes[0]?.totalPrice * (filteredSizes[0]?.discount / 100);
         setTotalPrice(filteredSizes[0]?.totalPrice);
         setDiscount(filteredSizes[0]?.discount);
         setNewPrice(filteredSizes[0]?.totalPrice - total);
       }
     }
   }, [discount, totalPrice, sizes]);

  const handleSubmit = async () => {
    if (productType === 'Product') {
      if (
        mainCategory === 'Select Main Category' ||
        childCategory === 'Select Sub Category' ||
        !title ||
        !description ||
        totalPrice === 0
      ) {
        toast.error('All fields are required');
        return;
      }
      const data = {
        product_type: productType,
        product_brand_id: brand,
        product_category_id: mainCategory,
        product_child_category_id: childCategory,
        product_title: title,
        product_description: description,
        old_price: totalPrice,
        discount,
        new_price: newPrice,
        shiping_fee: 0,
        product_advance: advance,
        delivered_days: deliverDay,
        featured_product: featuredProduct,
        product_new_arrival: newArrival,
        buynow,
        sizes,
        productCode,
        minQuantity,
        images: file,
      };
      await ProductServices.update(productItem._id, data)
        .then((res) => {
          console.warn('----------', res);
          toast.success('Product updated successfully');
          dispatch(getProductMethod());
          setTitle(null);
          setDescription(null);
          setBrand('Select Brand');
          setMainCategory('Select Main Category');
          setChildCategory('Select Sub Category');
          setTotalPrice(0);
          setDiscount(0);
          setNewPrice(0);
          // setShippingFee(0);
          setAdvance(null);
          setDeliverDay(null);
          setProductType('Product');
          setAccessoriesCondition(null);
          setAccessoriesType(null);
          setAccessoriesLocation(null);
          setFeaturedProduct(false);
          setNewArrival(false);
          setSizes([{ title: '', itemStatus: false, price: '0' }]);
          setBuyNow(false);
          setProductCode(null);
          setMinQuantity(0);
          handleClickOpenEditForm();
        })
        .catch((error) => {
          toast.error(error.response.data.messages);
          throw error;
        });
    } else {
      if (!title || !description || totalPrice === 0) {
        toast.error('All fields are required');
        return;
      }
      const data = {
        product_type: productType,
        product_brand_id: brand,
        product_title: title,
        product_description: description,
        old_price: totalPrice,
        discount,
        new_price: newPrice,
        shiping_fee: 0,
        delivered_days: deliverDay,
        accessories_condition: accessoriesCondition,
        accessories_type: accessoriesType,
        accessories_Location: accessoriesLocation,
        product_category_id: mainCategory,
        product_child_category_id: childCategory,
        productCode,
        images: file,
        minQuantity,
      };
      await ProductServices.update(productItem._id, data)
        .then((res) => {
          console.warn('----------', res);
          toast.success('Product updated successfully');
          dispatch(getAccessoriesProductMethod());
          setTitle(null);
          setDescription(null);
          setBrand('Select Brand');
          setMainCategory('Select Main Category');
          setChildCategory('Select Sub Category');
          setTotalPrice(0);
          setDiscount(0);
          setNewPrice(0);
          setSizes([{ title: '', itemStatus: false, price: '0' }]);
          // setShippingFee(0);
          setAdvance(null);
          setDeliverDay(null);
          setProductType('Product');
          setAccessoriesCondition(null);
          setAccessoriesType(null);
          setAccessoriesLocation(null);
          setFeaturedProduct(false);
          setNewArrival(false);
          setBuyNow(false);
          setProductCode(null);
          setMinQuantity(0);
          handleClickOpenEditForm();
        })
        .catch((error) => {
          if (!error.response.data.message) {
            toast.error(error.message, { position: 'top-right' });
          } else {
            toast.error(error.response.data.message, { position: 'top-right' });
          }
          throw error;
        });
    }
  };

  const handleItemChange = (index, field, value) => {
    setSizes((prevSizes) => prevSizes.map((item, idx) => (idx === index ? { ...item, [field]: value } : item)));
  };

  const handleNumberItemChange = (index, field, value) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(value)) {
      setSizes((prevSizes) => prevSizes.map((item, idx) => (idx === index ? { ...item, [field]: value } : item)));
    }
  };
  const handleRemoveItem = (index) => {
    setSizes((prevSizes) => prevSizes.filter((_, idx) => idx !== index));
  };
  const handleAddItem = (newItem) => {
    setSizes((prevSizes) => [...prevSizes, newItem]);
  };
  return (
    <div>
      <Dialog fullScreen open={openEditForm} onClose={handleClickOpenEditForm} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative', backgroundColor: '#70AD47' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClickOpenEditForm} aria-label="close">
              {/* <CloseIcon /> */}
              <Icon icon="ic:outline-close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Edit Product
            </Typography>
            {/* <Button autoFocus variant="outlined" sx={{ color: 'white', borderColor: 'white' }} onClick={handleSubmit}>
              Edit
            </Button> */}
          </Toolbar>
        </AppBar>
        <Grid container spacing={2} sx={{ textAlign: 'left', padding: '3%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'center' }}>
            <Typography variant="h5">Edit Product</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Select Brand
            </Typography>
            <FormControl fullWidth>
              <Select
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                sx={{ textAlign: 'left' }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '200px', // Set the same maximum height for the menu paper
                      overflowY: 'scroll', // Enable vertical scrolling
                    },
                  },
                }}
              >
                <MenuItem value="Select Brand" disabled>
                  Select Brand
                </MenuItem>
                {brandData.data !== null &&
                  brandData.data !== undefined &&
                  brandData.data.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Product Name
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Product Name"
              variant="outlined"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Product Code
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Product Code"
              variant="outlined"
              fullWidth
              value={productCode}
              onChange={(e) => setProductCode(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <FormControl>
              <FormLabel sx={{ marginRight: '10px', color: 'black' }} id="demo-controlled-radio-buttons-group">
                Select Product
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                value={productType}
                onChange={(e) => {
                  setProductType(e.target.value);
                  setFolderId(null);
                  setFile([]);
                }}
              >
                <FormControlLabel value="Product" control={<Radio />} label="Product" />
                <FormControlLabel value="Accessories" control={<Radio />} label="Accessories Product" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {productType === 'Product' ? (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Select Main Category
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={mainCategory}
                    onChange={(e) => {
                      setMainCategory(e.target.value);
                      setFolderId(e.target.value);
                    }}
                    sx={{ textAlign: 'left' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '200px', // Set the same maximum height for the menu paper
                          overflowY: 'scroll', // Enable vertical scrolling
                        },
                      },
                    }}
                  >
                    <MenuItem value="Select Main Category" disabled>
                      Select Main Category
                    </MenuItem>
                    {parentCategoryData.data !== null &&
                      parentCategoryData.data !== undefined &&
                      parentCategoryData.data.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Select Sub Category
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={childCategory}
                    onChange={(e) => setChildCategory(e.target.value)}
                    sx={{ textAlign: 'left' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '200px', // Set the same maximum height for the menu paper
                          overflowY: 'scroll', // Enable vertical scrolling
                        },
                      },
                    }}
                  >
                    <MenuItem value="Select Sub Category" disabled>
                      Select Sub Category
                    </MenuItem>
                    {subCategoryData.data !== null &&
                      subCategoryData.data !== undefined &&
                      subCategoryData.data.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                      Product Sizes
                    </Typography>
                    {!sizes || sizes.length === 0
                      ? null
                      : sizes?.map((item, index) => (
                          <Grid m={0.5} container key={index} spacing={2} alignItems={'center'}>
                            <Grid item xs={12} md={5}>
                              <Typography variant="caption">Label</Typography>
                              <TextField
                                id={`title-${index}`}
                                placeholder="Label"
                                name={`[${index}].title`}
                                value={item?.title}
                                variant="outlined"
                                fullWidth
                                onChange={(e) => handleItemChange(index, 'title', e.target.value)}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Typography variant="caption">Price</Typography>
                              <TextField
                                id={`totalPrice-${index}`}
                                type="number"
                                placeholder="Price"
                                name={`[${index}].totalPrice`}
                                value={item?.totalPrice}
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  handleNumberItemChange(index, 'totalPrice', e.target.value);
                                  handleNumberItemChange(
                                    index,
                                    'newPrice',
                                    e.target.value - e.target.value * (item?.discount / 100)
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Typography variant="caption">Discount</Typography>
                              <TextField
                                id={`discount-${index}`}
                                type="number"
                                name={`[${index}].discount`}
                                value={item?.discount}
                                variant="outlined"
                                fullWidth
                                onChange={(e) => {
                                  handleNumberItemChange(index, 'discount', e.target.value);
                                  handleNumberItemChange(
                                    index,
                                    'newPrice',
                                    item?.totalPrice - item?.totalPrice * (e.target.value / 100)
                                  );
                                }}
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={2}>
                              <Typography variant="caption">New Price</Typography>
                              <TextField
                                id={`newPrice-${index}`}
                                type="number"
                                name={`[${index}].discount`}
                                value={item?.newPrice}
                                variant="outlined"
                                fullWidth
                                disabled
                                onChange={() =>
                                  handleNumberItemChange(
                                    index,
                                    'newPrice',
                                    item?.totalPrice - item?.totalPrice * (item?.discount / 100)
                                  )
                                }
                                required
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Typography variant="caption">Stock Status</Typography>
                              <Select
                                fullWidth
                                name={`[${index}].itemStatus`} // Update to 'status'
                                value={item?.itemStatus} // Update to 'status'
                                onChange={(e) => handleItemChange(index, 'itemStatus', e.target.value)}
                              >
                                <MenuItem value>Yes</MenuItem>
                                <MenuItem value={false}>No</MenuItem>
                              </Select>
                            </Grid>
                            <Grid item xs={12} md={2} textAlign={'center'}>
                              <IconButton color="primary" onClick={() => handleRemoveItem(index)}>
                                <HighlightOffIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                    <Button
                      sx={{ margin: '10px' }}
                      variant="outlined"
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={() =>
                        handleAddItem({
                          title: '',
                          totalPrice: 0,
                          discount: 0,
                          newPrice: 0,
                          itemStatus: false,
                        })
                      }
                    >
                      Add Size
                    </Button>
                  </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Advance in percentage
                </Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Advance in percentage"
                  type="number"
                  variant="outlined"
                  fullWidth
                  value={advance}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => setAdvance(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FormLabel sx={{ textAlign: 'left', marginRight: '10px' }}>Select Product Type:</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox checked={featuredProduct} onChange={(e) => setFeaturedProduct(e.target.checked)} />
                    }
                    label="Featured Product"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={newArrival} onChange={(e) => setNewArrival(e.target.checked)} />}
                    label="New Arrival"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={buynow} onChange={(e) => setBuyNow(e.target.checked)} />}
                    label="Buy Now"
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Delivery Day
                </Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Delivery Day"
                  variant="outlined"
                  fullWidth
                  value={deliverDay}
                  inputProps={{ min: 0, max: 999 }}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => setDeliverDay(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Minimum Quantity
                </Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Total Price"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={minQuantity}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => setMinQuantity(e.target.value)}
                  required
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Select Main Category
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={mainCategory}
                    onChange={(e) => {
                      setMainCategory(e.target.value);
                      setFolderId(e.target.value);
                    }}
                    sx={{ textAlign: 'left' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '200px', // Set the same maximum height for the menu paper
                          overflowY: 'scroll', // Enable vertical scrolling
                        },
                      },
                    }}
                  >
                    <MenuItem value="Select Main Category" disabled>
                      Select Main Category
                    </MenuItem>
                    {parentCategoryData.data !== null &&
                      parentCategoryData.data !== undefined &&
                      parentCategoryData.data.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Select Sub Category
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={childCategory}
                    onChange={(e) => setChildCategory(e.target.value)}
                    sx={{ textAlign: 'left' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '200px', // Set the same maximum height for the menu paper
                          overflowY: 'scroll', // Enable vertical scrolling
                        },
                      },
                    }}
                  >
                    <MenuItem value="Select Sub Category" disabled>
                      Select Sub Category
                    </MenuItem>
                    {subCategoryData.data !== null &&
                      subCategoryData.data !== undefined &&
                      subCategoryData.data.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Condition
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      value={accessoriesCondition}
                      onChange={(e) => {
                        setAccessoriesCondition(e.target.value);
                      }}
                    >
                      <FormControlLabel value="New" control={<Radio />} label="New" />
                      <FormControlLabel value="Used" control={<Radio />} label="Used" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Type
                </Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Type"
                  variant="outlined"
                  fullWidth
                  value={accessoriesType}
                  onChange={(e) => setAccessoriesType(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Location
                </Typography>
                <TextField
                  id="outlined-basic"
                  placeholder="Location"
                  variant="outlined"
                  fullWidth
                  value={accessoriesLocation}
                  onChange={(e) => setAccessoriesLocation(e.target.value)}
                  required
                />
              </Grid>
            </>
          )}

          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Shipping Fee
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Shipping Fee"
              type="number"
              variant="outlined"
              fullWidth
              value={shippingFee}
              onChange={(e) => setShippingFee(e.target.value)}
              required
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Total Price
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Total Price"
              variant="outlined"
              type="number"
              fullWidth
              value={totalPrice}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setTotalPrice(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Discount in percentage
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="Discount in percentage"
              variant="outlined"
              type="number"
              fullWidth
              inputProps={{ min: 0, max: 100 }}
              onWheel={(e) => e.target.blur()} // Prevents changing the value using the scroll wheel
              value={discount}
              onChange={handleDiscountChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              New Price
            </Typography>
            <TextField
              id="outlined-basic"
              placeholder="New Price"
              variant="outlined"
              type="number"
              fullWidth
              value={newPrice}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Product Description
            </Typography>
            <RichTextEditors placeholder="Product Description" body={description} setBody={setDescription} />
            {/* <TextField
              id="outlined-basic"
              placeholder="Product Description"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
              required
            /> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              Upload Product Images (Total 5 images select)
            </Typography>
            <br />
            {/* <input accept=".png, .jpg, .jpeg" type="file" multiple id="select-image" onChange={selectFiles} required /> */}
            <Button
              variant="contained"
              size="small"
              startIcon={<Iconify icon="solar:gallery-bold" />}
              onClick={() => {
                if (!folderId) {
                  toast.error('Please select main category');
                  window.scrollTo(0, 0);
                  return;
                }
                handleModel();
              }}
            >
              Choose Image
            </Button>
          </Grid>
          <GalleyModel
            openModel={openModel}
            handleModel={handleModel}
            selectImageNumber={5}
            folderId={folderId}
            setFile={setFile}
            file={file}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ textAlign: 'left' }}>
            <Button
              autoFocus
              variant="contained"
              sx={{ color: 'white', borderColor: 'white', width: '10%' }}
              onClick={() => {
                handleSubmit();
                // handleClickOpenEditForm();
              }}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
