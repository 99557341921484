import { Helmet } from 'react-helmet-async';
import { filter, toLower, includes } from 'lodash';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  FormControl,
  Select,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import CustomPopup from '../client/Components/CustomPopup';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import {
  getVendorOrderCancelledMethod,
  getVendorOrderCompleteMethod,
  getVendorOrderConfirmMethod,
  getVendorOrderDeliverMethod,
  getVendorOrderMethod,
  getVendorOrderPendingMethod,
} from '../redux/slice/vendorOrderSlice';
import { IMAGE_BASE_URL } from '../redux/api/http-common';
import VendorOrderServices from '../redux/api/VendorOrderServices';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'first_name', label: 'Vendor Name', alignRight: false },
  { id: 'email', label: 'Vendor Email', alignRight: false },
  { id: 'orderId', label: 'Order Code', alignRight: false },
  { id: 'product_title', label: 'Product Name', alignRight: false },
  { id: 'product_type', label: 'Product Type', alignRight: false },
  { id: 'sizes', label: 'Product Size', alignRight: false },
  { id: 'accessories_condition', label: 'Condition', alignRight: false },
  { id: 'accessories_type', label: 'Type', alignRight: false },
  { id: 'accessories_Location', label: 'Location', alignRight: false },
  { id: 'delivered_days', label: 'Delivery Day', alignRight: false },
  { id: 'old_price', label: 'Total Price', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'new_price', label: 'New Price', alignRight: false },
  { id: 'quantity', label: 'Quantity', alignRight: false },
  { id: 'product_description', label: 'Descriptions', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'view', label: 'View', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      const lowerQuery = toLower(query);
      return (
        includes(toLower(_user?.vendor?.first_name), lowerQuery) ||
        includes(toLower(_user?.vendor?.last_name), lowerQuery) ||
        includes(toLower(_user?.vendor?.email), lowerQuery)
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function VendorOrderPages() {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userInfo);

  const [orderId, setOrderId] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [requestName, setRequestName] = useState('All');

  const [changeStatusMenu, setChangeStatusMenu] = useState(false);

  const [orderStatus, setOrderStatus] = useState(false);
  const [isDispute, setIsDispute] = useState(false);
  const [disputeDescription, setDisputeDescription] = useState('');
  const [ordersStatus, setOrdersStatus] = useState('');

  const data = useSelector((state) => state.vendorOrder);

  useEffect(() => {
    if (requestName === 'All') {
      dispatch(getVendorOrderMethod());
    } else if (requestName === 'Pending') {
      dispatch(getVendorOrderPendingMethod());
    } else if (requestName === 'Confirm') {
      dispatch(getVendorOrderConfirmMethod());
    } else if (requestName === 'Completed') {
      dispatch(getVendorOrderCompleteMethod());
    } else if (requestName === 'Delivered') {
      dispatch(getVendorOrderDeliverMethod());
    } else {
      dispatch(getVendorOrderCancelledMethod());
    }
  }, [requestName, dispatch]);

  const handleOpenChangeStatusMenu = (event, id, Status) => {
    setChangeStatusMenu(event.currentTarget);
    setOrderId(id);
    setOrderStatus(Status);
  };
  const handleCloseChangeStatusMenu = () => {
    setChangeStatusMenu(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    data.data !== null && data.data !== undefined ? data.data : [],
    getComparator(order, orderBy),
    filterName
  );
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleChangeStatus = async (e) => {
    if (userData?.role === 'admin' && (e.target.value === 'Disputed' || e.target.value === 'Return')) {
      setOrdersStatus(e.target.value);
      setIsDispute(true);
      handleCloseChangeStatusMenu();
      return;
    }
    const data = {
      orderId,
      status: e.target.value,
    };

    await VendorOrderServices.updateStatus(data)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (requestName === 'All') {
          dispatch(getVendorOrderMethod());
        } else if (requestName === 'Pending') {
          dispatch(getVendorOrderPendingMethod());
        } else if (requestName === 'Confirm') {
          dispatch(getVendorOrderConfirmMethod());
        } else if (requestName === 'Completed') {
          dispatch(getVendorOrderCompleteMethod());
        } else if (requestName === 'Delivered') {
          dispatch(getVendorOrderDeliverMethod());
        }
        handleCloseChangeStatusMenu();
        setOrderId(null);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        throw error;
      });
  };

  const handleDisputed = async () => {
    const data = {
      orderId,
      status: ordersStatus,
      disputeDescription,
    };
    await VendorOrderServices.updateStatus(data)
      .then((res) => {
        console.warn('--------', res);
        setIsDispute(false);
        toast.success(res?.data?.message);
        if (requestName === 'All') {
          dispatch(getVendorOrderMethod());
        } else if (requestName === 'Pending') {
          dispatch(getVendorOrderPendingMethod());
        } else if (requestName === 'Confirm') {
          dispatch(getVendorOrderConfirmMethod());
        } else if (requestName === 'Completed') {
          dispatch(getVendorOrderCompleteMethod());
        } else if (requestName === 'Delivered') {
          dispatch(getVendorOrderDeliverMethod());
        }
        handleCloseChangeStatusMenu();
        setOrderId(null);
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
        throw error;
      });
  };
  console.log(filteredUsers);
  return (
    <>
      <Helmet>
        <title> Vendor Orders | PakPrint Wishes </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {requestName} Vendor Orders
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{ margin: '5px' }}
              onClick={() => {
                setRequestName('All');
                dispatch(getVendorOrderMethod());
              }}
            >
              All Orders
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                setRequestName('Pending');
                dispatch(getVendorOrderPendingMethod());
              }}
            >
              Pending Orders
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                setRequestName('Confirm');
                dispatch(getVendorOrderConfirmMethod());
              }}
            >
              Confirm Orders
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                setRequestName('Completed');
                dispatch(getVendorOrderCompleteMethod());
              }}
            >
              Completed Orders
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                setRequestName('Cancelled');
                dispatch(getVendorOrderCancelledMethod());
              }}
            >
              Cancelled Orders
            </Button>
            <Button
              sx={{ margin: '5px' }}
              variant="contained"
              onClick={() => {
                setRequestName('Delivered');
                dispatch(getVendorOrderDeliverMethod());
              }}
            >
              Deliverd Orders
            </Button>
          </Box>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { _id } = row;
                    return (
                      <TableRow hover key={_id} tabIndex={-1}>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2" noWrap>
                            {row?.vendor?.first_name} {row?.vendor?.last_name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row.vendor.email}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="subtitle2" key={index} noWrap p={1}>
                                  {product?.orderId !== null ? product?.orderId : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="subtitle2" key={index} noWrap p={1}>
                                  {product?.product_id?.product_title !== null
                                    ? product?.product_id?.product_title
                                    : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.product_id?.product_type ? product?.product_id?.product_type : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <div key={index}>
                                  {!product.selectedSize ||
                                  Object.keys(product.selectedSize).length === 0 ||
                                  Object.keys(product.selectedSize).length === 1 ? (
                                    'N/A'
                                  ) : (
                                    <span>
                                      {product?.selectedSize?.title}
                                      <br />
                                      Total Price:{' '}
                                      <span
                                        style={{
                                          textDecoration: 'line-through',
                                        }}
                                      >
                                        ({product?.selectedSize?.totalPrice})
                                      </span>{' '}
                                      Discount: ({product?.selectedSize?.discount}%) New Price:(
                                      {product?.selectedSize?.newPrice})
                                    </span>
                                  )}
                                </div>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.product_id?.accessories_condition
                                    ? product?.product_id?.accessories_condition
                                    : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.product_id?.accessories_type
                                    ? product?.product_id?.accessories_type
                                    : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.product_id?.accessories_Location
                                    ? product?.product_id?.accessories_Location
                                    : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.product_id?.delivered_days ? product?.product_id?.delivered_days : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.old_price ? product?.old_price : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.discount ? product?.discount : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.new_price ? product?.new_price.toFixed(2) : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.quantity ? product?.quantity : 'N/A'}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          {row.products
                            ? row.products.map((product, index) => (
                                <Typography variant="body2" key={index} noWrap p={1}>
                                  {product?.product_id?.product_description ? (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: product?.product_id?.product_description.slice(0, 50),
                                      }}
                                    />
                                  ) : (
                                    'N/A'
                                  )}
                                </Typography>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          {row.products
                            ? row.products.map((product, index) => (
                                <a href={IMAGE_BASE_URL + product?.product_id?.images[0]} target="_black" key={index}>
                                  <img
                                    src={IMAGE_BASE_URL + product?.product_id?.images[0]}
                                    alt={product?.product_id?.product_title}
                                    style={{ width: '100px', padding: '2px' }}
                                  />
                                </a>
                              ))
                            : 'N/A'}
                        </TableCell>
                        <TableCell align="left">{row?.status}</TableCell>
                        <TableCell align="left">
                          <Button
                            disabled={
                              row?.status === 'Approved' || row?.status === 'Cancelled' || row?.status === 'Return'
                            }
                            // disabled={row.status === 'Cancelled' || row.vendor._id !== userData._id}
                            onClick={(e) => {
                              handleOpenChangeStatusMenu(e, _id, row.status);
                            }}
                          >
                            Change Status
                          </Button>
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            style={{ textDecoration: 'none', color: 'blue' }}
                            to={`/dashboard/vendor-order-details/${_id}`}
                          >
                            View Detail
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(changeStatusMenu)}
        anchorEl={changeStatusMenu}
        onClose={handleCloseChangeStatusMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {orderStatus === 'Delivered' || orderStatus === 'Disputed' || orderStatus === 'Return' ? (
          <FormControl fullWidth>
            <Select onChange={handleChangeStatus} sx={{ textAlign: 'left' }}>
              <MenuItem value="Change Status" disabled>
                Change Status
              </MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Disputed">Dispute</MenuItem>
              <MenuItem value="Return">Return Order</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        ) : (
          <FormControl fullWidth>
            <Select onChange={handleChangeStatus} sx={{ textAlign: 'left' }}>
              <MenuItem value="Change Status" disabled>
                Change Status
              </MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Confirm">Confirm</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              {/* <MenuItem value="Cancelled">Cancelled</MenuItem> */}
              <MenuItem value="Delivered">Deliverd</MenuItem>
            </Select>
          </FormControl>
        )}
      </Popover>

      <CustomPopup open={isDispute} setOpen={setIsDispute}>
        <Stack spacing={2} padding={4} sx={{ width: '500px' }}>
          <Typography variant="h5">Reason</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="disputeDescription"
            type="text"
            label="Description"
            onChange={(e) => setDisputeDescription(e.target.value)}
          />
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={() => handleDisputed()}>
              Submit
            </Button>
          </Box>
        </Stack>
      </CustomPopup>
    </>
  );
}
