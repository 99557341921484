import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  addToCart,
  cartInputQuantity,
  clearCart,
  decreaseCart,
  getTotals,
  removeCustomProductFromCart,
  removeFromCart,
} from '../../redux/slice/cartSlice';
import { BlueButton } from '../../Components/Components';
import { getUserDetails } from '../../redux/slice/userSlice';
import { imageBaseUrl } from '../../redux/api/http-common';

const AddToCartPage = () => {
  const { userInfo, userToken } = useSelector((state) => state.clientuser);
  const cart = useSelector((state) => state.cart);

  const [cartItems, setCartItems] = useState({ products: [], customproducts: [] });
  const dispatch = useDispatch();
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);
  React.useEffect(() => {
    if (userToken) {
      dispatch(getUserDetails());
    }
  }, [userToken, dispatch]);
  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    const products = { products: product, cartQty: 1, new_price: product?.new_price };
    dispatch(addToCart(products));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };
  const handleClearCart = () => {
    dispatch(clearCart());
  };
  useEffect(() => {
    setCartItems(!cart || !cart.cartItems ? { products: [], customproducts: [] } : cart.cartItems);
  }, [cart]);

  const handleInputChange = (index, value) => {
    if (!Array.isArray(cartItems.products)) {
      // If cartItems.products is not an array, set it to an empty array
      cartItems.products = [];
    }
    const newInputs = [...cartItems.products]; // Create a shallow copy of cartItems.products
    newInputs[index] = { ...newInputs[index], cartQuantity: Number(value) }; // Create a shallow copy of the item and update cartQuantity
    setCartItems({ ...cartItems, products: newInputs }); // Update cartItems with the modified products array
    const products = { products: newInputs[index], cartQty: Number(value), new_price: newInputs[index].new_price };
    if (Number(value) < 1) {
      handleRemoveFromCart(newInputs[index]);
    } else {
      dispatch(cartInputQuantity(products));
    }
  };
  return (
    <Grid container className="top-container row-center-flex" spacing={4}>
      <Helmet>
        <title>Cart | PakPrint Wishes </title>
      </Helmet>
      <Grid my={6} item xs={10} sm={10} md={7} lg={7} xl={7} p={1}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="space-between-container">
            <Box className="space-between-container">
              <Typography className="atc-header-text-font1">
                Total [{Number(cart?.cartItems?.products.length) + Number(cart?.cartItems?.customproducts.length)}{' '}
                ITEM(S)]
              </Typography>
            </Box>
            <Box className="space-between-container pointers" onClick={() => handleClearCart()}>
              <DeleteIcon fontSize="small" className="gray-color" />
              <Typography className="atc-header-text-font1">Clear Cart</Typography>
            </Box>
          </Grid>
          {Number(cart?.cartItems?.products.length) + Number(cart?.cartItems?.customproducts.length) === 0 ? (
            <Box className="cart-empty row-space-around-flex">
              <Typography className="co-header-text-font1">Your cart is currently empty</Typography>
              <Box className="start-shopping">
                <Link to="/" className="row-container">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-arrow-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                  </svg>
                  <Typography className="co-header-text-font2">Start Shopping</Typography>
                </Link>
              </Box>
            </Box>
          ) : (
            cartItems?.products &&
            cartItems?.products.map((cartItem, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="space-between-container"
                pt={2}
                key={cartItem._id}
              >
                <Grid container key={cartItem._id}>
                  <Grid item xs={10} sm={10} md={6} lg={6} xl={6}>
                    <Box className="icon-coms">
                      <Box className="space-between-container">
                        {cartItem?.images?.length !== 0 && (
                          <img
                            src={imageBaseUrl + cartItem?.images[0]}
                            alt={cartItem.product_title}
                            className="atc-box-img"
                          />
                        )}
                        <Box className="atc-header-text-font2">
                          <Typography className="atc-header-text-font2">{cartItem.product_title}</Typography>
                          {!cartItem?.selectedSize ||
                          Object.keys(cartItem.selectedSize).length === 0 ||
                          !cartItem?.selectedSize?.title ? null : (
                            <Typography className="co-header-text-font6" sx={{ marginLeft: '10px' }}>
                              Size: {cartItem?.selectedSize?.title}
                            </Typography>
                          )}
                        </Box>{' '}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={10} sm={10} md={4} lg={4} xl={4}>
                    {!cartItem?.discount || cartItem?.discount === 0 || cartItem?.discount === '0' ? (
                      <Box className="atc-col-container">
                        <Typography className="atc-header-text-font5">
                          Rs.
                          {Number(cartItem.old_price).toFixed(2)}
                        </Typography>
                        <Box className="icon-coms">
                          <DeleteIcon
                            className="gray-color pointers"
                            fontSize="small"
                            onClick={() => handleRemoveFromCart(cartItem)}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box className="atc-col-container">
                        <Typography className="atc-header-text-font5">
                          Rs.
                          {Number(cartItem.new_price).toFixed(2)}
                        </Typography>
                        <Typography className="atc-header-text-font3">
                          Rs.{Number(cartItem.old_price).toFixed(2)}
                        </Typography>
                        <Typography className="atc-header-text-font4">{cartItem.discount}% off</Typography>
                        <Box className="icon-coms">
                          <DeleteIcon
                            className="gray-color pointers"
                            fontSize="small"
                            onClick={() => handleRemoveFromCart(cartItem)}
                          />
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
                    <Box className="atc-counter-container">
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: 'bold',
                        }}
                      >
                        <span className="desc">
                          <Button
                            onClick={() =>
                              handleDecreaseCart({ ...cartItem, new_price: cartItems.products[index].new_price })
                            }
                            size="small"
                            className="atc-btn"
                          >
                            -
                          </Button>
                          <TextField
                            value={cartItems.products[index].cartQuantity}
                            sx={{ width: '100px', paddingX: '5px' }}
                            type="number"
                            onChange={(e) => handleInputChange(index, e.target.value)}
                          />
                          <Button className="atc-btn" onClick={() => handleAddToCart(cartItem)} size="small">
                            +
                          </Button>
                        </span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ))
          )}
          {Number(cart?.cartItems?.customproducts.length) === 0
            ? null
            : cartItems?.customproducts &&
              cartItems?.customproducts.map((cartItem, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="space-between-container"
                  pt={2}
                  key={index}
                >
                  <Grid container key={cartItem._id}>
                    <Grid item xs={10} sm={10} md={6} lg={6} xl={6}>
                      <Box className="icon-coms">
                        <Box className="space-between-container">
                          {cartItem?.images?.length !== 0 && (
                            <img
                              src={imageBaseUrl + cartItem?.images[0]}
                              alt={cartItem.titles}
                              className="atc-box-img"
                            />
                          )}

                          <Typography className="atc-header-text-font2">{cartItem.titles} (Custom Product)</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={10} sm={10} md={4} lg={4} xl={4}>
                      <Box className="atc-col-container">
                        <Typography className="atc-header-text-font5">
                          Rs.
                          {cartItem.totalPrice.toFixed(2)}
                        </Typography>
                        <Box className="icon-coms">
                          <IconButton
                            onClick={() => {
                              dispatch(removeCustomProductFromCart(index));
                            }}
                          >
                            <DeleteIcon className="gray-color pointers" fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={10} sm={10} md={2} lg={2} xl={2}>
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        QTY : {cartItem.cartQuantity}
                      </Typography>
                      <Typography className="atc-header-text-font4">
                        (Its not change the Quantity. The reason is that it is Custom Product.)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
        </Grid>
      </Grid>
      <Grid
        my={6}
        item
        xs={10}
        sm={10}
        md={3}
        lg={3}
        xl={3}
        sx={{ backgroundColor: 'white', padding: '30px !important' }}
      >
        <Typography>Order Summary</Typography>
        <Box className="space-between-container" py={2}>
          <Typography className="co-header-text-font6">
            Items Total({Number(cart?.cartItems?.products.length) + Number(cart?.cartItems?.customproducts.length)}{' '}
            items)
          </Typography>
          <Typography className="co-header-text-font2">
            Rs. {Number(cart?.cartTotalAmount.toFixed(2)) + Number(cart?.customTotal.toFixed(2))}
          </Typography>
        </Box>
        <Box className="space-between-container" py={2}>
          <Typography className="co-header-text-font6" sx={{ color: 'red !important' }}>
            Note: Ship Fee Calculate on the base of Distance and Weight.
          </Typography>
          {/* <Typography className="co-header-text-font2">
            Rs. {cart.cartTotalShip}
          </Typography> */}
        </Box>
        <Box className="space-between-container" py={2} sx={{ borderTop: '1px solid black' }}>
          <Typography className="co-header-text-font6">Total:</Typography>
          <Typography className="co-header-text-font2" sx={{ color: '#58A30A' }}>
            Rs. {Number(cart?.cartTotalAmount.toFixed(2)) + Number(cart?.customTotal.toFixed(2))}
          </Typography>
        </Box>
        <Box className="centered">
          <BlueButton
            component={Link}
            to={
              userInfo
                ? Number(cart?.cartItems?.products.length) + Number(cart?.cartItems?.customproducts.length) === 0
                  ? '/cart'
                  : '/checkout'
                : '/login'
            }
          >
            Proceed to Checkout
          </BlueButton>
        </Box>
        <Box className="start-shopping centered" pt={3}>
          <Link to="/" className="row-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            <Typography className="co-header-text-font2">Continue other Shopping</Typography>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddToCartPage;
