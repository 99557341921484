/* eslint-disable no-undef */
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BlueButton } from './Components';
import { addToCart } from '../redux/slice/cartSlice';
import { IMAGE_BASE_URL } from '../../redux/api/http-common';

const ProductCard = ({ image }) => {
  const dispatch = useDispatch();
  const filteredSizes =
    !image?.sizes || image?.sizes.length === 0
      ? []
      : image?.sizes.filter((item) => item.itemStatus === false || item.itemStatus === 'false');

  const newPrices =
    !filteredSizes || filteredSizes.length === 0 || !filteredSizes[0]?.newPrice
      ? image?.new_price
      : filteredSizes[0]?.newPrice;
  const oldPrices =
    !filteredSizes || filteredSizes.length === 0 || !filteredSizes[0]?.totalPrice
      ? image?.old_price
      : filteredSizes[0]?.totalPrice;
  const discounts =
    !filteredSizes || filteredSizes.length === 0 || !filteredSizes[0]?.discount
      ? image?.discount
      : filteredSizes[0]?.discount;
  // Add to cart
  const handleAddToCart = (product) => {
    const products = {
      products: product,
      cartQty: 1,
      selectedSize: !filteredSizes || filteredSizes.length === 0 ? null : filteredSizes[0],
      new_price: newPrices,
      old_price: oldPrices,
      discount: discounts,
    };
    dispatch(addToCart(products));
  };
  return (
    <Card className="featured-card">
      <CardActionArea
        component={Link}
        to={`/${image?.product_type === 'Accessories' ? 'product-accessories' : 'products'}/${image.product_title
          .replace(/\s+/g, '-')
          .toLowerCase()}/${image?._id}`}
        state={{ id: image._id }}
        sx={{ height: '90%' }}
      >
        <CardMedia
          component="img"
          height="250px"
          width="100%"
          sx={{ objectFit: '100%' }}
          image={IMAGE_BASE_URL + image?.images[0]}
          alt={image.product_title}
        />
        <CardContent sx={{ padding: '10px' }}>
          <Typography gutterBottom className="card-content-title">
            {!image.product_title ? null : image.product_title.slice(0, 25)}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography
              gutterBottom
              variant="subtitle1"
              sx={{
                color: '#58a30a',
                fontWeight: 200,
                fontFamily: 'Poppins',
              }}
            >
              RS. {Number(newPrices).toFixed(2)}
            </Typography>
            {!discounts ? null : (
              <Typography
                gutterBottom
                variant="body"
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Poppins',
                  color: 'rgba(0, 0, 0, 0.5);',
                  paddingLeft: '5px',
                }}
              >
                <del>RS.{oldPrices}</del>
                <span style={{ color: 'red' }}> {!discounts ? null : `${Number(discounts).toFixed(2)}%off`}</span>
              </Typography>
            )}
          </Box>
        </CardContent>
        {!image?.sizes || image?.sizes.length === 0 ? null : (
          <Typography
            gutterBottom
            variant="caption"
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              color: 'red',
            }}
          >
            Default size selected. Please explore and choose your preferred size.
          </Typography>
        )}
      </CardActionArea>
      <BlueButton className="card-btn" onClick={() => handleAddToCart(image)}>
        Add to Cart
      </BlueButton>
    </Card>
  );
};

export default ProductCard;
