import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  Grid,
  Box,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// components
import CustomPopup from '../client/Components/CustomPopup';
import Scrollbar from '../components/scrollbar';
import Label from '../components/label';
// sections
import { UserListToolbar } from '../sections/@dashboard/user';
// mock
import { IMAGE_BASE_URL } from '../redux/api/http-common';
import OrderService from '../redux/api/OrderService';
import { getOrderMethod } from '../redux/slice/orderSlice';
import OrderProductListHead from '../sections/@dashboard/user/OrderProductListHead';
import { getPublicVendorDetails } from '../redux/slice/publicVendorSlice';
import Iconify from '../components/iconify/Iconify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'orderId', label: 'Order Code', alignRight: false },
  { id: 'product_title', label: ' Product Name', alignRight: false },
  { id: 'product_type', label: 'Product Type', alignRight: false },
  { id: 'sizes', label: 'Product Size', alignRight: false },
  { id: 'accessories_condition', label: 'Condition', alignRight: false },
  { id: 'accessories_type', label: 'Type', alignRight: false },
  { id: 'accessories_Location', label: 'Location', alignRight: false },
  { id: 'advance', label: 'Advance', alignRight: false },
  { id: 'qusantity', label: 'Quantity', alignRight: false },
  { id: 'delivered_days', label: 'Delivery Day', alignRight: false },
  { id: 'shiping_fee', label: 'Shipping Fee', alignRight: false },
  { id: 'old_price', label: 'Total Price', alignRight: false },
  { id: 'discount', label: 'Discount', alignRight: false },
  { id: 'new_price', label: 'New Price', alignRight: false },
  { id: 'featured_product', label: 'Featured Product', alignRight: false },
  { id: 'product_new_arrival', label: 'New Arrival', alignRight: false },
  { id: 'buynow', label: 'Buy Now', alignRight: false },
  { id: 'product_description', label: 'Descriptions', alignRight: false },
  { id: 'images', label: 'Image', alignRight: false },
  { id: 'AssignOrder', label: 'Assign Order', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.product_id.product_title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OrderProductsPage() {
  const navigates = useNavigate();

  const dispatch = useDispatch();

  const { id } = useParams();

  const [data, setDatas] = useState(null);

  const orderList = useSelector((state) => state.order.data);

  const venderList = useSelector((state) => state.publicVendor);

  const user = useSelector((state) => state?.user?.userInfo);

  useEffect(() => {
    dispatch(getPublicVendorDetails());
    dispatch(getOrderMethod());
  }, [dispatch]);

  useEffect(() => {
    const result = !orderList ? null : orderList?.filter((item) => item?._id === id);
    setDatas(!result ? null : result[0]);
  }, [id, orderList]);

  const [selectVendor, setSelectVendor] = useState('Select Vendor');

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isReAsign, setIsReAsign] = useState(false);

  const [reAsignObject, setReAsignObject] = useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    !data || !data.products || data.products.length === 0 ? [] : data.products,
    getComparator(order, orderBy),
    filterName
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredUsers.length - page * rowsPerPage);

  const isNotFound = !filteredUsers.length && !!filterName;

  const handleCompleteOrder = async () => {
    const result = {
      vendor: selectVendor,
      order: data._id,
    };
    await OrderService.assignCompete(result)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        dispatch(getPublicVendorDetails());
        dispatch(getOrderMethod());
        setIsReAsign(false);
        setSelectVendor('Select Vendor');
        // navigates('/dashboard/orders');
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  const handleAssignOrder = async (e, productId, isAssign) => {
    if (isAssign) {
      setIsReAsign(true);
      setReAsignObject({
        vendor: e.target.value,
        order: data._id,
        product: productId,
      });
      return;
    }
    const result = {
      vendor: e.target.value,
      order: data._id,
      product: productId,
    };
    await OrderService.assignOneItem(result)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (res.data.success) {
          dispatch(getPublicVendorDetails());
          dispatch(getOrderMethod());
        }
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };
  const handleReAssignProduct = async () => {
    await OrderService.assignOneItem(reAsignObject)
      .then((res) => {
        console.warn('--------', res);
        toast.success(res?.data?.message);
        if (res.data.success) {
          setIsReAsign(false);
          dispatch(getPublicVendorDetails());
          dispatch(getOrderMethod());
        }
      })
      .catch((error) => {
        if (!error.response.data.message) {
          toast.error(error.message, { position: 'top-right' });
        } else {
          toast.error(error.response.data.message, { position: 'top-right' });
        }
      });
  };

  return (
    <>
      <Helmet>
        <title> Order Product | PakPrint Wishes </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Customer Information
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Order Details
              </Typography>
              <Button
                variant="contained"
                startIcon={<Iconify icon="uit:print" />}
                onClick={() => {
                  navigates(`/dashboard/orders/print-order/${id}`);
                }}
              >
                Print
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} p={2} className="product-order-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Name
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.user_id?.first_name} {data?.user_id?.last_name}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Email
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.user_id?.email}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Phone No.
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.user_id?.phone}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Customer Address
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.user_id?.address} {data?.user_id?.city}, {data?.user_id?.state}, {data?.user_id?.country}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} p={2} className="product-order-box">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Order#
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.orderId}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Total Amount
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.sub_total.toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Total Shiping Fee
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.total_shipping_fee}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Grand Total
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.all_totals.toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Delivery Address
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.delivery_address}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1" gutterBottom>
                Payment Method
              </Typography>
              <Typography variant="body1" gutterBottom>
                {data?.payment_method}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Assign Complete Order
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" justifyContent="center">
              <FormControl fullWidth>
                <Select
                  value={data?.assignedVendor}
                  onChange={(e) => setSelectVendor(e.target.value)}
                  sx={{ textAlign: 'left' }}
                >
                  {venderList.data !== null &&
                    venderList.data !== undefined && [
                      <MenuItem
                        key="assign-to-admin"
                        sx={{
                          backgroundColor: '#becc03',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: '#103996',
                            // Add any other styles for the hover state here
                          },
                        }}
                        value={user?._id}
                      >
                        Assign To Admin
                      </MenuItem>,
                      ...venderList.data.map((item, index) => (
                        <MenuItem key={index} value={item._id}>
                          {item.first_name} {item.last_name}
                        </MenuItem>
                      )),
                    ]}
                </Select>
              </FormControl>
              <Button
                // disabled={data && data.completeAsign === true}
                variant="contained"
                sx={{ marginTop: '10px' }}
                onClick={handleCompleteOrder}
              >
                Assign Complete Order
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ marginTop: '3%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Order Products
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrderProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow hover key={row._id} tabIndex={-1}>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {row.orderId ? row.orderId : 'N/A'}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2" noWrap>
                          {row?.product_id?.product_title ? row.product_id.product_title : 'N/A'}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {row.product_id.product_type ? row.product_id.product_type : 'N/A'}
                      </TableCell>
                      <TableCell align="left">
                        {!row.selectedSize ||
                        Object.keys(row.selectedSize).length === 0 ||
                        Object.keys(row.selectedSize).length === 1 ? (
                          'N/A'
                        ) : (
                          <div style={{ marginBottom: '10px' }}>
                            <span>
                              {row?.selectedSize?.title}
                              <br />
                              Total Price:{' '}
                              <span
                                style={{
                                  textDecoration: 'line-through',
                                }}
                              >
                                ({row?.selectedSize?.totalPrice})
                              </span>{' '}
                              Discount: ({row?.selectedSize?.discount}%) New Price:({row?.selectedSize?.newPrice})
                            </span>
                          </div>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {row.product_id.accessories_condition ? row.product_id.accessories_condition : 'N/A'}
                      </TableCell>
                      <TableCell align="left">
                        {row.product_id.accessories_type ? row.product_id.accessories_type : 'N/A'}
                      </TableCell>
                      <TableCell align="left">
                        {row.product_id.accessories_Location ? row.product_id.accessories_Location : 'N/A'}
                      </TableCell>
                      <TableCell align="left">
                        {row.product_id.product_advance ? row.product_id.product_advance : 'N/A'}
                      </TableCell>
                      <TableCell align="left">{row?.qusantity ? row?.qusantity : 'N/A'}</TableCell>
                      <TableCell align="left">
                        {row.product_id.delivered_days ? row.product_id.delivered_days : 'N/A'}
                      </TableCell>
                      <TableCell align="left">
                        {row.product_id.shiping_fee ? row.product_id.shiping_fee : 'N/A'}
                      </TableCell>
                      <TableCell align="left">{row.product_id.old_price ? row.product_id.old_price : 'N/A'}</TableCell>
                      <TableCell align="left">{row.product_id.discount ? row.product_id.discount : 'N/A'}</TableCell>
                      <TableCell align="left">
                        {row.product_id.new_price ? row.product_id.new_price.toFixed(2) : 'N/A'}
                      </TableCell>
                      <TableCell align="left">
                        <Label color={(row.product_id.featured_product === true && 'success') || 'error'}>
                          {row.product_id.featured_product === true ? 'YES' : 'NO'}
                        </Label>
                      </TableCell>

                      <TableCell align="left">
                        <Label color={(row.product_id.product_new_arrival === true && 'success') || 'error'}>
                          {row.product_id.product_new_arrival === true ? 'YES' : 'NO'}
                        </Label>
                      </TableCell>
                      <TableCell align="left">
                        <Label color={(row.product_id.buynow === true && 'success') || 'error'}>
                          {row.product_id.buynow === true ? 'YES' : 'NO'}
                        </Label>
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: '10px' }}>
                        {row.product_id.product_description ? (
                          <div dangerouslySetInnerHTML={{ __html: row.product_id.product_description.slice(0, 50) }} />
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <img
                          src={IMAGE_BASE_URL + row.product_id.images[0]}
                          alt={row.product_id.product_title}
                          style={{ width: '100px' }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <FormControl sx={{ width: 200 }}>
                          <Select
                            value={row?.assignedVendor}
                            size="small"
                            onChange={(e) => {
                              handleAssignOrder(e, row.product_id._id, row.assigned);
                            }}
                            sx={{ textAlign: 'left' }}
                          >
                            {venderList.data !== null &&
                              venderList.data !== undefined && [
                                <MenuItem
                                  key="assign-to-admin"
                                  sx={{
                                    backgroundColor: '#becc03',
                                    color: 'white',
                                    '&:hover': {
                                      backgroundColor: '#103996',
                                      // Add any other styles for the hover state here
                                    },
                                  }}
                                  value={user?._id}
                                >
                                  Assign To Admin
                                </MenuItem>,
                                ...venderList.data.map((item, index) => (
                                  <MenuItem key={index} value={item._id}>
                                    {item.first_name} {item.last_name}
                                  </MenuItem>
                                )),
                              ]}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <CustomPopup open={isReAsign} setOpen={setIsReAsign}>
        <Stack p={4} spacing={2}>
          <Typography>Are You Sure You Want to Re Assign this Order</Typography>
          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" gap={2}>
              <Button variant="contained" onClick={handleReAssignProduct}>
                Yes
              </Button>
              <Button variant="contained" onClick={() => setIsReAsign(false)}>
                No
              </Button>
            </Box>
          </Box>
        </Stack>
      </CustomPopup>
    </>
  );
}
