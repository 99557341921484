import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import ProductServices from '../../../redux/api/ProductServices';
import { IMAGE_BASE_URL } from '../../../redux/api/http-common';
import ProgressBar from '../../progress/ProgressBar';

const ProductDetails = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    getDetail();
  }, [id]);
  const getDetail = async () => {
    await ProductServices.getbyId(id)
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        setLoading(false);
      });
  };
  return (
    <Box sx={{ width: '100%' }}>
      {loading === true ? (
        <ProgressBar />
      ) : (
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '20px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
          }}
        >
          <Grid item xs={12} py={2}>
            <Typography variant="h5">Product Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Product Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_title ? 'N/A' : data?.product_title}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Product Code</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.productCode ? 'N/A' : data?.productCode}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Brand Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_brand_id?.name ? 'N/A' : data?.product_brand_id?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Product Type</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_type ? 'N/A' : data?.product_type}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Main Category</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_category_id?.name ? 'N/A' : data?.product_category_id?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Sub Category</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_child_category_id?.name ? 'N/A' : data?.product_child_category_id?.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Accessories Condition</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.accessories_condition ? 'N/A' : data?.accessories_condition}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Accessories Type</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.accessories_type ? 'N/A' : data?.accessories_type}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Accessories Location</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.accessories_Location ? 'N/A' : data?.accessories_Location}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Product Size</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.sizes || data?.sizes.length === 0
                ? 'N/A'
                : data?.sizes.map((item, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <span
                        style={{
                          textDecoration: item.itemStatus ? 'line-through' : 'none',
                          color: item.itemStatus ? 'red' : 'gray',
                        }}
                      >
                        {item.title}
                        <br />
                        Total Price:{' '}
                        <span
                          style={{
                            textDecoration: 'line-through',
                          }}
                        >
                          ({item?.totalPrice})
                        </span>{' '}
                        Discount: ({item?.discount}%) New Price:({item?.newPrice})
                      </span>
                    </div>
                  ))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Advance</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_advance ? 'N/A' : data?.product_advance}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Delivery Day</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.delivered_days ? 'N/A' : data?.delivered_days}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Total Price</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.old_price ? 'N/A' : data?.old_price}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Discount</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.discount ? 'N/A' : data?.discount}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">New Price</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.new_price ? 'N/A' : data?.new_price.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Featured Product</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.featured_product ? 'N/A' : data?.featured_product === true ? 'YES' : 'NO'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">New Arrival</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_new_arrival ? 'N/A' : data?.product_new_arrival === true ? 'YES' : 'NO'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Buy Now</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.buynow ? 'N/A' : data?.buynow === true ? 'YES' : 'NO'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Status</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.status ? 'N/A' : data?.status === true ? 'Approved' : 'Disapproved'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1">Descriptions</Typography>
          </Grid>
          <Grid item xs={12} sm={6} pt={1}>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {!data || !data?.product_description ? (
                'N/A'
              ) : (
                <div dangerouslySetInnerHTML={{ __html: data?.product_description }} />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} pt={1} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {!data || !data?.images || !data?.images.length === 0
              ? 'N/A'
              : data?.images.map((item, index) => (
                  <img
                    src={`${IMAGE_BASE_URL}${item}`}
                    alt={data?.product_title}
                    key={index}
                    height={'200px'}
                    style={{ padding: '10px' }}
                  />
                ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ProductDetails;
